import Vue from 'vue';
import moment from 'moment';

export default class DateTimeHelper {

  public static getStartOfTheDayMoment(): moment.Moment {
    return Vue.moment.utc().subtract(0, 'days').startOf('day');
  }

  public static isJSDate(input: any): boolean {
    return input instanceof Date;
  }

  public static messageTime(inputTime: moment.MomentInput, isUTC: boolean): string {
    if (!inputTime) {
      return '';
    }
    const timeMoment = moment(inputTime);
    const now = moment(new Date());
    if (now.isSame(timeMoment, 'day')) {
      return timeMoment.utc(isUTC).format('HH:mm');
    } else {
      return timeMoment.utc(isUTC).format('DD MMM HH:mm');
    }
  }

}
