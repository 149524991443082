<template>
  <div>
    <div class="select-wrapper">
      <div class="select">
        <select label-float
                id="countrySelect"
                class="select-text"
                :label="$t('events.page.country')"
                filterable
                v-model="formData.country_id"
                :error-text="$t(errors.country_id)"
                :no-data-text="$t('events.create.step1.noValue')"
                @change="countryChanged"
                ref="selectCountry"
                full-width>
          <option v-for="(country,index) in countryList" :key="index"
                  :label="country.name"
                  :value="country.id"
          ></option>
        </select>
        <span class="select-highlight"></span>
        <span class="select-bar"></span>
        <label class="select-label">{{ $t('events.page.country') }}</label>
      </div>
      <div class="select">
        <mu-circular-progress v-show="this.isLoading" class="progress"></mu-circular-progress>
        <select v-show="!this.isLoading"
                id="citySelect"
                class="select-text"
                label-float
                :label="$t('events.page.city')"
                v-model="formData.city_id"
                :no-data-text="$t('events.create.step1.noValue')"
                @change="cityChanged(formData.city_id)"
                ref="selectCity"
                :disabled="!formData.country_id">
          <option v-for="(city, index) in listOfCities"
                  :key="index"
                  :label="city.name"
                  :value="city.id"
          ></option>
        </select>
        <span class="select-highlight"></span>
        <span class="select-bar"></span>
        <label class="select-label">{{ $t('events.page.city') }}</label>
      </div>
    </div>
    <!--<location-picker
        :hasPicker="true"
        :address="locationPickerAddress"
    ></location-picker>-->
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "event-location",
  computed: {
    ...mapState("locationStore", ["countryList", "cityList", "loading"]),
  },
  props: {
    country: {
      type: Object,
      default: null
    },
    city: {
      type: Object,
      default: null
    },
    address: {
      type: String
    }
  },
  watch: {
    country: {
      handler() {
        this.initLocationData();
      }
    },
    "formData.country_id": {
      deep: true,
      handler() {
        if (this.validateCountry()) {
          this.cityRequest();
          this.isEmptyCountry = false;
          this.$emit('eventLocation', this.formData);
          return;
        }
        this.isEmptyCountry = true;
      }
    },
    "formData.city_id": {
      deep: true,
      handler() {
        this.$emit('eventLocation', this.formData);
      }
    },
    city: {
      immediate: true,
      deep: true,
      handler() {
        if (this.city && this.city.id) {
          this.formData.city_id = this.city.id;
          this.formData.city_name = this.city.name;
        }
        if (this.formData.city_id && this.validateCity()) {
          Promise.all([
            this.updateLocationPicker()
          ]).then(() => {
            this.$emit('eventLocation', this.formData);
          })
        }
      }
    },
    cityList: {
      handler() {
        this.isLoading = true;
          this.fillList()
      }
    }
  },
  data: () => ({
    listOfCities: [],
    pagination: 0,
    count: 0,
    isLoading: false,
    isEmptyCountry: true,
    errors: {
      address: null,
      country_id: null,
      city_id: null,
    },
    formData: {
      address: null,
      country_id: null,
      city_id: null,
      country_name: null,
      city_name: null
    },
    locationPickerAddress: ''
  }),
  created() {
    this.$store.dispatch("locationStore/countryList");
    if (this.country !== null && this.country.id) {
      // this.$store.dispatch("locationStore/cityList", this.country.id);
      this.cityRequest();
    }
  },
  mounted() {
    this.initLocationData();
  },
  methods: {

    /* Moved the code from mounted into a method
    */
    initLocationData() {
      if (this.country !== null) {
        if (this.country.id) {
          this.formData.country_id = this.country.id;
        }
        if (this.city.id) {
          this.formData.city_id = this.city.id;
        }
        if (this.address) {
          this.formData.address = this.address;
        }
      }
    },

    /* Actions needed when country has been changed
     */
    countryChanged(){
      // Clean up the city select
      this.formData.city_id = null;
      this.formData.city_name = '';
      this.formData.city = null;
      this.listOfCities = [];
      this.pagination = 0;
      this.count = 0;
    },
    /* Passing country and city name
       * to obtain geo coords from Google Places API
       * @param value is a number of city in DB
       */
    cityChanged(value) {
      let idToName = function (id, dataSource) {
        let source = dataSource;
        let result = '';
        if (!id && id !== 0) {
          return result;
        }
        source = source || [];
        for (let i = 0; i < source.length; i++) {
          if (source[i].id == id) {
            result = source[i].name;
            break;
          }
        }
        return result;
      };

      this.$emit('cityChanged', {
        country: idToName(this.formData.country_id, this.countryList),
        city: idToName(value, this.cityList)
      });

      this.$emit('eventLocation', this.formData);
    },
    validate() {
      return true;
    },
    handlePrev() {
      this.$emit("prev");
    },
    cityRequest() {
      if (this.formData.country_id) {
        this.$store.dispatch("locationStore/cityList", this.formData.country_id)
      }
    },
    validateCountry() {
      if (!this.formData.country_id) {
        this.setError({message_key: "errors.validation.field_is_required"}, "country_id");
      } else {
        this.rmErr("country_id");
        return true;
      }
    },
    validateCity() {
      if (!this.formData.city_id) {
        this.setError({message_key: "errors.validation.field_is_required"}, "city_id");
      } else {
        this.rmErr("city_id");
        return true;
      }
    },
    updateLocationPicker() {
      this.locationPickerAddress = this.formData.address;
    },
    setError(err, name) {
      this.errors[name] = err.message_key;
    },
    rmErr(name) {
      this.errors[name] = null;
    },
    fillList() {
      for (this.count; this.count < this.pagination; this.count++) {
        if (this.cityList[this.count]) {
          this.listOfCities.push(this.cityList[this.count])
        } else {
          this.isLoading = false;
          break
        }
      }
      setTimeout(() => {
        if (this.pagination < this.cityList.length) {
          this.pagination += 1000;
          this.fillList()
        }
      }, 200)
    },
  }
};
</script>

<style scoped lang="scss">
@import "src/_styles/base/custom-select";

.progress {
  display: flex;
  width: 100% !important;
  justify-content: center;
}

//.select-wrap {
//  max-width: 280px;
//}
//
//.select {
//  width: 100%;
//}
//
//.city-select {
//  padding-left: 12px;
//}

.step-1-layout,
.step-2-layout {
  width: initial;
  max-width: initial;

}

.select-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

</style>
