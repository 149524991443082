<template>
  <div class="section section-create-event component">
    <div class="section-body">
      <div class="biletum-stepper biletum-stepper-create-event"
           :class="{ 'transparent-inactive' : finished }"
      >
        <span
            class="biletum-step"
            :class="{'biletum-step-complete' : stepperActiveNum() > 1 || stepperActiveNum() === 1, 'biletum-step-current' : activeStep == 1}"
            @click="showStep(1)"
        >
          {{ titles[0] }}
        </span>
        <span
            class="biletum-step"
            :class="{'biletum-step-complete' : stepperActiveNum() > 2 ||  stepperActiveNum() == 2, 'biletum-step-current' : activeStep == 2}"
            @click="showStep(2)"
        >
          {{ titles[1] }}
        </span>
      </div>
      <div
          class="biletum-box"
          :class="{'create-event-finished' : finished}"
      >
        <div class="tab-content" :class="{'none' : activeStep !== 1}">
          <!-- STEP 1 -->
          <div class="step-1-layout">
            <div class="part part-1">
              <mu-container>
                <div class="control">
                  <mu-text-field
                    :label="$t('events.create.step1.eventName')"
                    v-model="formData.title"
                    placeholder=""
                    :error-text="$t(errors.title)"
                    full-width
                    label-float
                    max-length="70"
                  ></mu-text-field>
                  <div class="error-messages" v-if="$v.formData.title.$invalid">
                    <div v-if="!$v.formData.title.required" class="error-message">{{ $t('errors.validation.required') }}</div>
                  </div>
                </div>

                <event-language-selector
                  :preSelectedLanguages="formData.languages"
                  :readOnly="editMode === 'editEvent'"
                  @eventLanguagesUpdated="handleEventLanguagesUpdated"
                ></event-language-selector>

                <div class="dates-row">
                  <div class="part part-1">
                    <div class="control">
                    <mu-date-input
                        :label="$t('events.create.step1.eventStartsAt')"
                        v-model="startDate"
                        type="dateTime"
                        @change="autoSetEndDate"
                        label-float
                        full-width
                        landscape
                        view-type="list"
                        clock-type="24hr"
                        :first-day-of-week="firstDayOfWeek()"
                        :date-time-format="getCalendarDateTimeFormat()"
                    ></mu-date-input>
                    <div class="error-messages" v-if="$v.startDate.$invalid">
                      <div v-if="!$v.startDate.required" class="error-message">{{ $t('errors.validation.date_must_selected') }}</div>
                      <div v-if="!$v.startDate.dateNotInPast" class="error-message">{{ $t('errors.validation.date_should_not_in_past') }}</div>
                    </div>
                  </div>
                  </div>
                  <div class="part part-2">
                    <div class="control">
                    <mu-date-input
                        :label="$t('events.create.step1.eventEndsAt')"
                        :class="{'biletum-mu-input-disabled' : !startDate}"
                        v-model="endDate"
                        type="dateTime"
                        :min-date="endDateMinimum"
                        @change="checkEndDate"
                        :first-day-of-week="firstDayOfWeek()"
                        label-float
                        full-width
                        landscape
                        view-type="list"
                        ref="endDatePicker"
                        clock-type="24hr"
                        :date-time-format="getCalendarDateTimeFormat()"
                    ></mu-date-input>
                      <div class="error-messages" v-if="$v.endDate.$invalid">
                        <div v-if="!$v.endDate.required" class="error-message">{{ $t('errors.validation.date_must_selected') }}</div>
                        <div v-if="!$v.endDate.dateNotInPast" class="error-message">{{ $t('errors.validation.date_should_not_in_past') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info info-date-note">{{ $t('title.date_&_time_should_match_event_location') }}</div>
                <div class="control">
                <mu-text-field
                    :label="$t('events.page.location')"
                    v-model="formData.address"
                    placeholder="error input"
                    full-width
                    label-float
                    @blur="addressToCoords"
                ></mu-text-field>
                  <div class="error-messages" v-if="$v.formData.address.$invalid">
                    <div v-if="!$v.formData.address.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                  </div>
                </div>
                <div class="control">
                <mu-text-field :label="$t('events.create.step4.url')"
                               v-model="formData.url"
                               placeholder=""
                               :error-text="$t(errors.url)"
                               full-width
                               label-float
                >

                </mu-text-field>
                  <div class="error-messages" v-if="$v.formData.url.$invalid">
                    <div v-if="!$v.formData.url.url" class="error-message">{{ $t('errors.validation.url_not_valid') }}</div>
                    <div v-if="!$v.formData.url.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                  </div>
                </div>
                <br/>
                <br/>
                <br/>

                <div style="display: flex; justify-content: space-between;">
                  <div style="width: 45%;">
                    <div class="control">
                    <upload-org-logo
                        :imageUrl="eventInfo.org_logo"
                        @orgLogoData="setOrgLogoData"
                        class="create-event-org-logo"
                        ref="uploadImageOrgLogo"
                    >
                      {{ $t('uploadImages.organizerLogo') }}
                    </upload-org-logo>
                    <div class="error-messages" v-if="$v.formData.org_logo.$invalid">
                      <div v-if="!$v.formData.org_logo.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                    </div>
                  </div>
                  </div>
<!--                  <div style="width: 45%;">-->
<!--                    <mu-switch v-model="useAccessTypeRegister"-->
<!--                               :label="$t('events.create.step1.iWillGoFunction')"-->
<!--                               label-left-->
<!--                               @change="toggleAccessTypeRegister"-->
<!--                               full-width></mu-switch>-->
<!--                    <div style="font-size: 12px; font-weight: 300; padding-top: 12px;">-->
<!--                      {{ $t('events.create.step1.iWillGoFunctionDescription') }}-->
<!--                    </div>-->
<!--                  </div>-->
                  <!--    TODO: WIP-->
                  <div style="width: 45%;">
                    <mu-switch v-model="useAccessTypeFree"
                               :label="$t('events.create.step1.freeEnterFunction')"
                               label-left
                               @change="toggleAccessTypeRegister"
                               full-width></mu-switch>
                    <div style="font-size: 12px; font-weight: 300; padding-top: 12px;">
                      {{ $t('events.create.step1.freeEnterFunctionDescription') }}
                    </div>
                  </div>
                  <!--    TODO: WIP-end-->
                </div>
              </mu-container>
            </div>

            <div class="part part-2">
              <div class="control image-error">
                <upload-image
                  :imageUrl="eventInfo.image"
                  @imageData="setImageData"
                  class="create-event-header-image"
                  ref="uploadImageMain"
                >
                  {{ $t('uploadImages.headerImage') }}
                </upload-image>
                <div class="error-messages" v-if="$v.formData.image.$invalid">
                  <div v-if="!$v.formData.image.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- STEP 1 END -->
        </div>
        <div class="tab-content" :class="{'none' : activeStep !== 2}">
          <!-- STEP 2 -->
          <div class="step-1-layout">
            <div class="part part-1">
              <div class="control">
              <event-location
                  v-if="editMode === 'editEvent'"
                  :country="this.eventInfo.country"
                  :city="this.eventInfo.city"
                  @eventLocation="setLocationData"
                  @cityChanged="requestAddressCoords"
                  ref="eventLocation"></event-location>

              <event-location
                  v-else
                  @eventLocation="setLocationData"
                  @cityChanged="requestAddressCoords"
                  ref="eventLocation"></event-location>
                <div class="error-messages" v-if="$v.formData.country_id.$invalid">
                  <div v-if="!$v.formData.country_id.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="step-2-row">
            <div class="control">
            <mu-text-field :label="$t('events.create.step3.aboutYourEvent')"
                           v-model="formData.descr"
                           :placeholder="$t('events.create.step3.aboutYourEventPlaceholder')"
                           multi-line
                           :max-length="5000"
                           full-width
                           label-float
                           :rows="3"
            ></mu-text-field>
            <div class="error-messages" v-if="$v.formData.descr.$invalid">
              <div v-if="!$v.formData.descr.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
            </div>
          </div>
          </div>

          <div class="step-2-row">
            <div class="control">
              <mu-text-field :label="$t('events.create.step3.agenda')"
                             v-model="formData.agendas"
                             :placeholder="$t('events.create.step3.agendaPlaceholder')"
                             multi-line
                             :max-length="1500"
                             full-width
                             label-float
                             :rows="3"
              ></mu-text-field>
              <div class="error-messages" v-if="$v.formData.agendas.$invalid">
                <div v-if="!$v.formData.agendas.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
              </div>
            </div>
          </div>
          <div class="control">
          <upload-photos :photos="setEventPhotos()" @photosData="setPhotosData">{{
              $t('events.create.step2.eventImages')
            }}
          </upload-photos>
            <div class="error-messages" v-if="$v.formData.photos.$invalid">
              <div v-if="!$v.formData.photos.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
            </div>
          </div>
          <div class="step-2-layout">
            <div class="part part-1">
              <div class="control">
                <mu-select
                  :label="$t('events.create.step1.chooseEventType')"
                  filterable
                  v-model="formData.event_type_id"
                  full-width
                  label-float
                  :no-data-text="$t('events.create.step1.noValue')"
                >
                  <mu-option
                    v-for="(type, index) in typeList"
                    :key="index"
                    :label="type.name"
                    :value="type.id"
                  ></mu-option>
                </mu-select>
                <div class="error-messages" v-if="$v.formData.event_type_id.$invalid">
                  <div v-if="!$v.formData.event_type_id.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="step-2-layout">
            <div class="part part-1">
              <div class="control">
                <mu-text-field :label="$t('events.create.step3.organizer')"
                               v-model="formData.org_name"
                               placeholder=""
                               full-width
                               label-float
                ></mu-text-field>

                <div class="error-messages" v-if="$v.formData.org_name.$invalid">
                  <div v-if="!$v.formData.org_name.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>
              <div class="control">
                <mu-text-field :label="$t('events.create.step3.email')"
                               v-model="formData.org_email"
                               placeholder=""
                               full-width
                               label-float
                ></mu-text-field>
                <div class="error-messages" v-if="$v.formData.org_email.$invalid">
                  <div v-if="!$v.formData.org_email.email" class="error-message">{{ $t('errors.validation.email_invalid') }}</div>
                  <div v-if="!$v.formData.org_email.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>
              <div class="control">
                <mu-text-field :label="$t('events.create.step3.phone')"
                               v-model="formData.org_phone"
                               placeholder=""
                               full-width
                               label-float
                ></mu-text-field>
                <div class="error-messages" v-if="$v.formData.org_phone.$invalid">
                  <div v-if="!$v.formData.org_phone.phoneValid" class="error-message">{{$t('errors.validation.phone_only_num_and_+') }}</div>
                  <div v-if="!$v.formData.org_phone.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>
              <div class="control">
                <mu-text-field :label="$t('events.create.step4.buy_booth_url')"
                               v-model="formData.buy_booth_url"
                               placeholder=""
                               full-width
                               label-float
                >
                </mu-text-field>
                <div class="error-messages" v-if="$v.formData.buy_booth_url.$invalid">
                  <div v-if="!$v.formData.buy_booth_url.url" class="error-message">{{ $t('errors.validation.url_not_valid') }}</div>
                  <div v-if="!$v.formData.buy_booth_url.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>

              <div class="step-2-row">
                <div class="control">
                <mu-text-field :label="$t('events.create.step4.buy_ticket_url')"
                               v-model="formData.buy_ticket_url"
                               placeholder=""
                               :error-text="$t(errors.buy_tickets_url)"
                               full-width
                               label-float
                >
                </mu-text-field>
                <div class="error-messages" v-if="$v.formData.buy_ticket_url.$invalid">
                  <div v-if="!$v.formData.buy_ticket_url.url" class="error-message">{{ $t('errors.validation.url_not_valid') }}</div>
                  <div v-if="!$v.formData.buy_ticket_url.isRequired" class="error-message">{{ $t('errors.validation.required') }}</div>
                </div>
              </div>
              </div>

              <mu-switch v-model="make_public"
                         :label="$t('events.create.step1.publishEvent')"
                         @change="checkBeforePublish"
                         full-width
              ></mu-switch>


              </div>

          </div>


          <div class="fill-all-to-publish"
               v-if="!publishAllowed"
          >
            {{ $t('events.create.endNote2') }}
          </div>
<!--          <div class="error" v-if="this.checkBeforePublishError"-->
          <div class="error" v-if="this.errors.someFieldsHasErrors"
          >
            {{ $t('errors.CheckErrors') }}
          </div>
          <!-- STEP 2 END -->
        </div>

        <div class="step-actions" v-if="!finished">
          <span
              class="step-action-button step-action-button-bordered"
              @click="cancel"
          >
            {{ $t("button.cancel") }}
          </span>
          <span
              class="step-action-button step-action-button-filled"
              @click="submit"
          >
           {{ $t("button.save") }}
          </span>
          <span
              class="step-action-button step-action-button-bordered"
              :class="{'disabled' : activeStep > 2 || activeStep === 2}"
              @click="handleNext"
          >
             {{ $t("button.next") }}
          </span>
        </div>

        <div class="end-note" v-if="!finished">
          {{ $t('events.create.endNote') }}
        </div>

        <!-- Finished! Go to event page -->
        <div v-if="finished && !this.eventError">
          <p class="finished">{{ this.$t('events.create.finished') }}</p>
          <p class="finished-descr">
            <span v-if="this.editMode === 'editEvent'">{{ this.$t('events.create.yourEventHasBeenSaved') }}</span>
            <span v-else>{{ this.$t('events.create.yourEventHasBeenAdded') }}</span>
          </p>
          <div class="step-actions step-actions-centered step-actions-eventcreated">
            <span
                v-if="this.editMode === 'editEvent'"
                class="step-action-button step-action-button-bordered"
                @click="showStep(1)"
            >
              {{ $t('events.create.returnToEditing') }}
            </span>
            <span
                v-else
                class="step-action-button step-action-button-bordered"
                @click="cancel"
            >
              {{ $t('events.create.toEventsList') }}
            </span>
            <span
                v-if="this.editMode === 'editEvent'"
                class="step-action-button step-action-button-bordered"
                @click="viewSavedEvent"
            >
             {{ $t('events.create.viewTheEvent') }}
            </span>
            <span
                v-else
                class="step-action-button step-action-button-bordered"
                @click="viewCreatedEvent"
            >
             {{ $t('events.create.viewTheEvent') }}
            </span>
          </div>
        </div>
        <div v-else-if="finished && this.eventError" class="finished-with-error">
          <p class="finished">{{ this.$t('events.create.error') }}</p>
          <p class="finished-descr">
            <span v-if="this.editMode === 'editEvent'">{{ this.$t('events.create.yourEventHasNotBeenSaved') }}</span>
            <span v-else>{{ this.$t('events.create.yourEventHasNotBeenAdded') }}</span>
          </p>
          <div class="step-actions step-actions-centered step-actions-eventcreated">
            <span
                class="step-action-button step-action-button-bordered"
                @click="showStep(1)"
            >
              {{ $t('events.create.editAfterError') }}
            </span>
            <span
                class="step-action-button step-action-button-bordered"
                @click="cancel"
            >
              {{ $t('events.create.toEventsList') }}
            </span>
            <span
                v-if="this.editMode === 'editEvent'"
                class="step-action-button step-action-button-bordered"
                @click="viewSavedEvent"
            >
             {{ $t('events.create.viewTheEvent') }}
            </span>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import uploadImage from './components/uploadImages/uploadImage';
import uploadPhotos from './components/uploadImages/uploadPhotos';
import eventLocation from './components/eventLocation/eventLocation';
import { startGoogleMaps } from '../main';
import UploadOrgLogo from './components/uploadImages/uploadOrgLogo';
import DateTimeHelper from '@/_helpers/date-time.helper';
import EventLanguageSelector from '@/_components/create-event/event-language-selector';
import _cloneDeep from 'lodash.clonedeep';
import { maxLength, required, url, email, } from "vuelidate/lib/validators";
import ValidationHelper from "@/_helpers/validation.helper";

let startDate;

const isValidStartDateNotInPast = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }

  const currentDate = new Date().getTime() / 1000;

  const dateStart = new Date(value).getTime() / 1000;
  startDate = dateStart;
  return +currentDate < dateStart;
};

const isValidEndDateNotInPast = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }

  if (startDate) {
    const dateEnd = new Date(value).getTime() / 1000;
    return dateEnd > startDate;
  } else {
    return true;
  }
};

export default {
  name: 'create-event-main',
  components: { UploadOrgLogo, uploadImage, uploadPhotos, eventLocation, EventLanguageSelector },
  props: {
    editMode: {
      type: String,
      default: 'createEvent'
    }
  },
  computed: {
    ...mapGetters({}),
    ...mapState("eventStore", ["categoryList", "typeList", "image", "uploadedImage", "eventInfo", "eventError"]),
    ...mapState("uploadedImageStore", ["image", "uploadedImage", "photos"]),
    ...mapState("locationStore", ["countryList", "cityList"]),
    finished() {
      return this.activeStep > 2;
    },
    // make_public: {
    //   get() {
    //     return !this.formData.is_private;
    //   },
    //   set(val) {
    //     return val;
    //   }
    // },
  },
  watch: {
    "formData.address": {
      deep: true,
      handler() {
        if (!this.$v.formData.address.$invalid) {
          this.setAddressData(this.formData);
        }
      }
    },
    make_public: {
      handler() {
        this.$v.formData.$touch();
        if(this.editMode === 'createEvent'){
         this.formData.is_private = this.make_public;
        }
      }
    },
    startDate: {
      handler() {
        if (DateTimeHelper.isJSDate(this.startDate)) {
          this.formData.date_start = this.$moment(this.startDate).format("YYYY-MM-DDTHH:mm");
        }
      }
    },
    end: {
      deep: true,
      handler() {
        this.validateEndDate();
      }
    },
    endDate: {
      handler() {
        if (this.editMode === 'editEvent') {
          if (!DateTimeHelper.isJSDate(this.endDate)) {
            this.endDate = new Date(this.endDate);
          }
        }
        if (DateTimeHelper.isJSDate(this.endDate)) {
          this.formData.date_end = this.$moment(this.endDate).format("YYYY-MM-DDTHH:mm");
        }
      }
    },
    eventInfo: {
      deep: true,
      handler() {
        if (this.editMode === 'editEvent' && this.$route.name === 'promo-page-cabinet-main-page') {
          this.initEditMode();
        }
      }
    }
  },
  data() {
    return {
      make_public: false,
      activeStep: 1,
      publishAllowed: true,
      stepperActiveNum: () => {
        return this.activeStep - 1
      },
      endDatePickerValue: null,
      startDate: null,
      endDate: null,
      endDateMinimum: null,
      useAccessTypeFree: false,
      errors: {
        title: null,
        descr: null,
        agendas: '',
        date_start: '',
        date_end: '',
        url: null,
        buy_tickets_url: null,
        buy_booth_url: null,
        country_id: null,
        city_id: null,
        org_email: null,
        org_phone: null,
        someFieldsHasErrors: null,
      },
      formData: {
        title: null,
        descr: null,
        agendas: '',
        image: null,
        org_logo: null,
        date_start: null,
        date_end: null,
        url: null,
        photos: null,
        address: null,
        country_id: null,
        city_id: null,
        event_type_id: null,
        // categories: null,
        org_name: null,
        org_email: null,
        org_phone: null,
        buy_ticket_url: null,
        buy_booth_url: null,
        is_private: true,
        access_type: 'register',
        latitude: '',
        longitude: '',
        languages: ['en'],
      },
      formDataToSend: {
        title: null,
        descr: null,
        agendas: '',
        image: null,
        org_logo: null,
        date_start: null,
        date_end: null,
        url: null,
        photos: null,
        address: null,
        country_id: null,
        city_id: null,
        org_name: null,
        org_email: null,
        org_phone: null,
        event_type_id: null,
        buy_ticket_url: null,
        buy_booth_url: null,
        is_private: true,
        access_type: 'register',
        latitude: '',
        longitude: '',
        languages: ['en'],
      },
      titles: [
        this.$t('events.create.generalInformation'),
        this.$t('events.create.additionalInformation')
      ],
      cityName: '',
      countryName: '',
      checkBeforePublishError: false
    }
  },
  created() {
    this.$store.dispatch("locationStore/countryList");
    this.$store.dispatch("eventStore/eventType");
    // this.make_public = false;
  },
  mounted() {
    if (this.editMode === 'editEvent' && this.$route.name === 'promo-page-cabinet-main-page') {
      // this if triggers event getter when we are inside organizer cabinet.
      // Without this, event data is empty on first load in that component
      this.$store.dispatch('eventStore/event', this.$route.params.eventId).then(() => {
        this.initEditMode();
      });
    } else if (this.editMode === 'editEvent') {
      this.initEditMode();
    } else {
      this.$store.dispatch('eventStore/clearEventImage');
      this.$store.dispatch('eventStore/clearOrgLogo');
      this.$refs.uploadImageMain.showExternalImage('');
      this.$refs.uploadImageOrgLogo.showExternalImage('');
    }
  },
  validations: {
    startDate: {
      required,
      dateNotInPast: isValidStartDateNotInPast
    },
    endDate: {
      required,
      dateNotInPast: isValidEndDateNotInPast
    },
    formData: {
      title: {
        required,
        maxLength: maxLength(70),
      },
      address: {
        // required,
        isRequired() {
          if (this.make_public) {
            return !!this.formData.address
          } else {
            return true
          }
        },
      },
      url: {
        // required,
        isRequired() {
          if (this.make_public) {
            return !!this.formData.url
          } else {
            return true
          }
        },
        url
      },
      event_type_id: {
        // required,
        isRequired() {
          if (this.make_public) {
            return !!this.formData.event_type_id
          } else {
            return true
          }
        },
      },
      org_email:{
        email,
        isRequired() {
          if (this.make_public) {
            return !!this.formData.org_email
          } else {
            return true
          }
        },
      },
      org_phone: {
        // required,
        isRequired() {
          if (this.make_public) {
            return !!this.formData.org_phone
          } else {
            return true
          }
        },
        phoneValid: ValidationHelper.isValidPhoneNumber,
      },
      buy_booth_url: {
        url,
        isRequired() {
          if (this.make_public) {
            return !!this.formData.buy_booth_url
          } else {
            return true
          }
        }
      },
      buy_ticket_url: {
        url,
        isRequired() {
          if (this.make_public) {
            return !!this.formData.buy_ticket_url
          } else {
            return true
          }
        }
      },
      org_name: {
        isRequired() {
          if(this.make_public){
            return !!this.formData.org_name
          } else {
            return true
          }
        }
      },
      agendas: {
        isRequired() {
          if (this.make_public) {
            return !!this.formData.agendas
          } else {
            return true
          }
        }
      },
      photos: {
        isRequired() {
          if (this.make_public) {
            return !!this.formData.photos
          } else {
            return true
          }
        }
      },
      descr: {
        isRequired() {
          if (this.make_public) {
            return !!this.formData.descr
          } else {
            return true
          }
        }
      },
      country_id: {
        isRequired() {
          if (this.make_public) {
            return !!this.formData.country_id && !!this.formData.city_id
          } else {
            return true
          }
        }
      },
      org_logo: {
        isRequired() {
          if (this.make_public) {
            return !!this.formData.org_logo
          } else {
            return true
          }
        }
      },
      image: {
        isRequired() {
          if (this.make_public) {
            return !!this.formData.image
          } else {
            return true
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('eventStore', ['createEvent']),
    handleEventLanguagesUpdated(eventData) {
      this.formData.languages = _cloneDeep(eventData);
    },
    toggleAccessTypeRegister() {
      if (this.useAccessTypeFree) {
        this.formData.access_type = 'free';
      } else {
        this.formData.access_type = 'register';
      }
    },
    setEventPhotos() {
      if (this.editMode === 'editEvent') {
        return this.eventInfo.photos;
      }
      return null;
    },
    initEditMode() {
      // TODO: refactor cleanup
      // this.$store.dispatch("eventStore/eventCategory");

      this.formData.id = this.eventInfo.id;
      this.formData.title = this.eventInfo.title;
      if (this.eventInfo.address) {
        this.formData.address = this.eventInfo.address;
      }
      if (this.eventInfo.country && this.eventInfo.city) {
        this.formData.country_id = this.eventInfo.country.id;
        this.formData.city_id = this.eventInfo.city.id;
        this.setLocationData({
          'country_id': this.eventInfo.country.id,
          'city_id': this.eventInfo.city.id
        });
      }
      if (this.eventInfo.date_start) {
        this.formData.date_start = this.$moment.utc(this.eventInfo.date_start).format('YYYY-MM-DDTHH:mm');
        this.startDate = this.$moment.utc(this.eventInfo.date_start).format('YYYY-MM-DDTHH:mm');
      }
      if (this.eventInfo.date_end) {
        this.formData.date_end = this.$moment.utc(this.eventInfo.date_end).format('YYYY-MM-DDTHH:mm');
        this.endDate = this.$moment.utc(this.eventInfo.date_end).format('YYYY-MM-DDTHH:mm');
      }
      /* if (this.eventInfo.categories) {
          this.formData.categories = this.eventInfo.categories.map(item => item.id);
        } */
      if (this.eventInfo.event_type && this.eventInfo.event_type.id) {
        this.formData.event_type_id = this.eventInfo.event_type.id;
      }
      if (this.eventInfo.access_type === 'free') {
        this.formData.access_type = this.eventInfo.access_type;
        this.useAccessTypeFree = true;
        this.formData.access_type = 'free';
      } else {
        this.useAccessTypeFree = false;
        this.formData.access_type = 'register';
      }
      if (this.eventInfo.image) {
        this.formData.image = this.eventInfo.image;
      }
      if (this.eventInfo.org_logo) {
        this.formData.org_logo = this.eventInfo.org_logo;
      }
      if (this.eventInfo.photos) {
        this.formData.photos = this.eventInfo.photos.map(item => item);
      }
      if (this.eventInfo.org_name) {
        this.formData.org_name = this.eventInfo.org_name;
      }
      if (this.eventInfo.org_email) {
        this.formData.org_email = this.eventInfo.org_email;
      }
      if (this.eventInfo.org_phone) {
        this.formData.org_phone = this.eventInfo.org_phone;
      }
      if (this.eventInfo.descr) {
        this.formData.descr = this.eventInfo.descr;
      }
      if (this.eventInfo.agendas) {
        // TODO: obtain technical task on agenda and implement it
        this.formData.agendas = this.eventInfo.agendas;
      }
      if (this.eventInfo.url) {
        this.formData.url = this.eventInfo.url;
      }
      if (this.eventInfo.buy_ticket_url) {
        this.formData.buy_ticket_url = this.eventInfo.buy_ticket_url;
      }
      if (this.eventInfo.buy_booth_url) {
        this.formData.buy_booth_url = this.eventInfo.buy_booth_url;
      }
      // Checking using typeof because is_private is a boolean
      if (typeof this.eventInfo.is_private != 'undefined') {
        this.formData.is_private = this.eventInfo.is_private;
        this.make_public = !this.eventInfo.is_private;
      }

      if (this.eventInfo.languages) {
        this.formData.languages = _cloneDeep(this.eventInfo.languages); // Cloning against direct mutation of store
      }
    },
    checkBeforePublish() {

      let isError;

      for (name in this.errors) {
        if (this.errors[name]) {
          isError = !!this.errors[name]
        }
      }

      this.checkBeforePublishError = isError;

      this.formData.is_private = !this.make_public;
      // if (this.formData.is_private === true) {
      //   if (this.noEmptyFields(this.formData)) {
      //     this.publishAllowed = true;
      //     this.formData.is_private = this.make_public;
      //     console.log(this.formData.is_private, 'true')
      //   } else {
      //     this.publishAllowed = false;
      //     this.formData.is_private = true;
      //   }
      //   return;
      // }
      // this.formData.is_private = true;
    },
    setPhotosData(value) {
      this.formData.photos = value
    },
    setImageData(value) {
      this.formData.image = value.image
    },
    setOrgLogoData(value) {
      this.formData.org_logo = value.image
    },

    /* Cancel button behavior
       */
    cancel() {
      if (this.editMode === 'editEvent') {
        this.$emit("cancelEditing");
      } else {
        this.$router.push({ name: 'event-list' });
      }
    },

    /* Go to the event page from create event
      */
    viewCreatedEvent() {
      this.$router.push({ name: 'event-info', params: { eventId: '' + this.eventInfo.id} })
    },

    /* Exit edit mode, show the event page
      */
    viewSavedEvent() {
      this.$emit('cancelEditing', this.eventInfo.id);
    },

    /* Scroll the page to the top after step change
       */
    scrollToTop() {
      /* scroll to the top after step change */
      window.scrollTo(0, 0);
    },

    /* Set endDate date to the end of the same day 23:59
       * If 23:59 is less than 1 hour later,
       * make it the next day
       */
    autoSetEndDate(val) {
      let start =new Date(this.startDate).getTime() / 1000;
      let end =new Date(this.endDate).getTime() / 1000;
      // minimum end date is 1 hour ahead, used in the :min-date prop
      this.endDateMinimum = new Date(this.startDate.getTime() + 60 * 60 * 1000);

      // val has to be present and be a JS Date object. Simple check using getTime
      if (val && ('getTime' in val)) {
        if (!this.endDate || start > end) {
          let endDate = new Date(val);
          endDate.setHours(23, 59, 59);

          // If auto-set endDate time is closer than 1 hour, move it to next day 23:59
          if (endDate.getTime() - val.getTime() < (60 * 60 * 1000)) {
            endDate.setTime(endDate.getTime() + 1000 * 60 * 60 * 24);
          }

          this.endDate = endDate;
        }
      }
    },

    /* Muse UI datepicker does not check for time part of min-date prop.
     * Have to check ourselves.
     */
    checkEndDate(val) {

      // Clear error on change
      this.rmErr('date_end');

      // Set hours to startDate.hours + 1
      if (val.getTime() - this.startDate < 60 * 60 * 1000) {
        this.endDate = new Date(this.startDate.getTime() + 60 * 60 * 1000);
      }
    },

    setAddressData(value) {
      this.formData.address = value.address;
    },
    addressToCoords() {
      if (this.countryName && this.cityName) {
        this.requestAddressCoords({
          country: this.countryName,
          city: this.cityName
        });
      }
    },
    setLocationData(value) {
      this.formData.country_id = value.country_id;
      this.formData.city_id = value.city_id;
    },
    setCoords(lat, lon) {
      this.formData.latitude = lat;
      this.formData.longitude = lon;
    },
    requestAddressCoords(value) {
      /* attempt to get Lat Long coords from Google and save them into mapData.map */
      let address = this.formData.address;
      let country = value.country;
      let city = value.city;
      this.cityName = city;
      this.countryName = country;
      let _this = this;

      if (process.env.VUE_APP_MAP_PROVIDER !== 'google') {
        return;
      }

      startGoogleMaps().then(function () {

        let request = {
          query: [address, city, country].filter((x) => x).join(', '),
          fields: ['name', 'geometry']
        };

        // PlacesService requires a DIV element or a map element
        if (window.google) {
          let service = new window.google.maps.places.PlacesService(document.createElement('div'));

          // send request, use response in callback
          service.findPlaceFromQuery(request, function (results, requestStatus) {
            if (requestStatus === window.google.maps.places.PlacesServiceStatus.OK) {
              _this.setCoords(results[0].geometry.location.lat(), results[0].geometry.location.lng());
            }
          });
        }
      });
    },

    /* Some fields in formData should not be sent,
       * because the API will return errors
       * formData needs preprocessing
       * etc. Not good? Yes.
       */
    prepareFormData() {
      for (let i in this.formDataToSend) {
        this.formDataToSend[i] = this.formData[i];
      }
    },

    noEmptyFields(data) {
      let result = true;
      for (let i in data) {
        if (Object.prototype.hasOwnProperty.call(data, i)) {
          if (i === 'is_private' || i === 'latitude' || i === 'longitude' || i === 'access_type') {
            continue;
          }
          if (!data[i]) {
            result = false;
            break;
          }
        }
      }

      return result;
    },

    /* Submit handler
       */
    submit() {
      this.$v.formData.$touch();
      this.checkBeforePublish()
      this.errors.someFieldsHasErrors = true
      if (this.$v.formData.$pending || this.$v.formData.$invalid) {
        /* vuelidation error(s) */
        return;
      }

      this.$store.commit("eventStore/setEventError", '');
      this.prepareFormData();

      // Cloning because: we have to delete languages on PATCH, but only for sending.
      let _formData = _cloneDeep(this.formDataToSend);

      let _id = this.formData.id;
      if (this.formDataToSend.is_private === false) {
        // Attempting to create a public event
        if (this.noEmptyFields(this.formDataToSend)) {
          if (this.editMode === 'editEvent') {

            // PATCH route does not accept languages because changing languages means lots of HARD WORK for backenders.
            delete _formData.languages;

            this.$store.dispatch("eventStore/updateEvent", { formData: _formData, id: _id });
          } else {
            this.createEvent(this.formDataToSend);
          }
          // Shows the success screen
          this.activeStep = 3;
        }
      } else {
        // Attempting to create a private event
        // if (this.validateEventName() &&this.validateStartDate() && this.validateEndDate()) {
          if (this.editMode === 'editEvent') {

            // PATCH route does not accept languages because changing languages means lots of HARD WORK for backenders.
            delete _formData.languages;

            this.$store.dispatch("eventStore/updateEvent", { formData: _formData, id: _id });
          } else {
            this.createEvent(this.formDataToSend);
          }
          // Shows the success screen
          this.activeStep = 3;
        // }
      }
    },

    /* For when you need to show some step by number
       */
    showStep(stepNumber) {
      this.activeStep = stepNumber;
    },

    /* Show previous step
       */
    handlePrev(currentStep) {
      this.activeStep = currentStep - 1;
      this.scrollToTop();
    },

    /* Show next step
       */
    handleNext() {
      this.activeStep++;
      this.scrollToTop();
    },

    /* Show field error
       */
    setError(err, name) {
      this.errors[name] = err.message_key
    },

    /* Hide field error
       */
    rmErr(name) {
      if (name in this.errors)
        this.errors[name] = null;
    },

    /* mu-datepicker translation
       * https://muse-ui.org/#/en-US/date-input
       * NOTE! formatDateDisplay method is NOT DOCUMENTED!
       */
    getCalendarDateTimeFormat() {
      let lang = this.$route.params.lang;
      let textData;

      let textStrings = {
        'zh': {
          firstDay: 0,
          dayAbbreviation: ['日', '一', '二', '三', '四', '五', '六'],
          dayList: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
          monthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
          monthLongList: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
        },
        'en': {
          firstDay: 0,
          dayAbbreviation: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          dayList: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          monthLongList: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        },
        'lt': {
          firstDay: 1,
          dayAbbreviation: ['S.', 'P.', 'A.', 'T.', 'K.', 'Pn.', 'Š.'],
          dayList: ['S.', 'P.', 'A.', 'T.', 'K.', 'Pn.', 'Š.'],
          monthList: ['Saus', 'vas', 'kovas', 'bal', 'geg', 'birz', 'liepa', 'rugp', 'rugs', 'spalis', 'lapkr', 'gr'],
          monthLongList: ['Sausis', 'Vasaris', 'Kovas', 'Balandis', 'Gegužė', 'Birželis', 'Liepa', 'Rugpjūtis', 'Rugsėjis', 'Spalis', 'Lapkritis', 'Gruodis'],
        },
        'ru': {
          firstDay: 1,
          dayAbbreviation: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          dayList: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          monthList: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
          monthLongList: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        },
        'uk': {
          firstDay: 1,
          dayAbbreviation: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          dayList: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          monthList: ['Січ', 'Лют', 'Берез', 'Квіт', 'Трав', 'Черв', 'Лип', 'Серп', 'Верес', 'Жовт', 'Листоп', 'Груд'],
          monthLongList: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
        }
      };

      if (!textStrings[lang]) {
        textData = textStrings.en;
      } else {
        textData = textStrings[lang];
      }

      return {
        formatDisplay(date) {
          return `${textData.dayList[date.getDay()]}, ${textData.monthList[date.getMonth()]} ${date.getDate()}`;
        },
        formatMonth(date) {
          return `${textData.monthLongList[date.getMonth()]} ${date.getFullYear()}`;
        },
        getWeekDayArray() {
          let firstDayOfWeek = textData.firstDay ? textData.firstDay : 0; // firstDay or fallback to Sunday
          let beforeArray = [];
          let afterArray = [];
          for (let i = 0; i < textData.dayAbbreviation.length; i++) {
            if (i < firstDayOfWeek) {
              afterArray.push(textData.dayAbbreviation[i]);
            } else {
              beforeArray.push(textData.dayAbbreviation[i]);
            }
          }
          return beforeArray.concat(afterArray);
        },
        getMonthList() {
          return textData.monthList;
        },
        formatDateDisplay(date) {
          return `${date.getDate()} ${textData.monthList[date.getMonth()]}`;
        },
      }
    },
    firstDayOfWeek() {
      if(this.$i18n.locale === 'ru' || this.$i18n.locale === 'lt' || this.$i18n.locale === 'uk') {
        return 1
      } else {
        return 0
      }
    },
  }
}
</script>

<style scoped lang="scss">
.finished {
  font-size: 2.2rem;
  text-align: center;

  &-descr {
    font-size: 1.6rem;
    text-align: center;
  }

  &-with-error {
    .finished,
    .finished-descr {
      color: #d00;
    }
  }
}

.image-error {
  height: 150px;

  .error-messages {
    margin-top: 70px;
  }
}

.end-note {
  text-align: right;
  font-weight: 300;
}

.fill-all-to-publish {
  font-size: 12px;
  font-weight: 100;
  max-width: 285px;
}

.error {
  color: #d00;
}

.step-action-button.disabled {
  pointer-events: none;
}

.info {
  color: $biletum-light-blue;
  font-size: 1.2rem;
  text-align: left;
  margin-top: 4px;

  &-date-note {
    padding-top: 1.25rem; /* Some space for field's error text, https://biletum.myjetbrains.com/youtrack/issue/AW-346#focus=Comments-4-789.0-0 */
    position: relative;

    &::before {
      content: "*";
      position: absolute;
      right: 100%;
      top: 1.25em;
    }
  }
}
</style>
